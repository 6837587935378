@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	border: 0;
	padding: 0;
	margin: 0;	
  
}

html, body, #root {
	@apply h-screen;
	font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-gradient {
	background: linear-gradient(0deg,  #fff, #D8DCE9);
}

.blue-mobile {
  background-color: #D8DCE9;
}

